<template>
  <div class="add-Parking-dialog">
    <Wdialog
      ref="addParkingDialog"
      title="新增车场"
      width="50%"
      @wConfirm="handleEditParking"
    >
      <el-form
        ref="parkingFormRef"
        :model="parkingFormData"
        :rules="parkingFormRules"
        label-position="top"
        class="add-parking-form"
      >
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item prop="parking" label="车场名称">
              <el-input
                v-model="parkingFormData.parking"
                autocomplete="off"
                placeholder="请输入车场名称"
                clearable
              /> </el-form-item
          ></el-col>
          <el-col :span="8">
            <el-form-item prop="project" label="关联项目">
              <el-select
                v-model="parkingFormData.project"
                placeholder="请选择关联项目"
                clearable
              >
                <el-option
                  v-for="item in projectOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="8">
            <el-form-item prop="chargeType" label="临停收费模板">
              <el-select
                :disabled="!parkingFormData.project"
                v-model="parkingFormData.chargeType"
                placeholder="请选择临停收费模板"
                clearable
                multiple
                collapse-tags
              >
                <el-option
                  v-for="item in chargeTypeOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item prop="callCenter" label="呼叫中心">
              <el-input
                v-model="parkingFormData.callCenter"
                autocomplete="off"
                placeholder="请输入呼叫中心"
                clearable
              /> </el-form-item
          ></el-col>
          <el-col :span="8">
            <el-form-item prop="factor" label="可用厂商">
              <el-select
                v-model="parkingFormData.factor"
                placeholder="请选择可用厂商"
                clearable
              >
                <el-option
                  v-for="item in factorOptions"
                  :key="item.id"
                  :label="item.device_factory_name"
                  :value="item.id"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>
        </el-row>
      </el-form>
    </Wdialog>
  </div>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import API from "@/plugins/api.js";
import Wdialog from "@/components/wDialog/wDialog.vue";
export default {
  components: {
    Wdialog,
  },

  emits: ["submit"],
  setup(props, { emit }) {
    const addParkingDialog = ref(null);
    const parkingFormRef = ref(null);
    const factorOptions = ref([]);
    const projectOptions = ref([]);
    onMounted(() => {
      chargeTypeOptions.value = [];
      API.getProjectOptions({}).then((res) => {
        projectOptions.value = res;
      });
      API.getFactorOptions({}).then((res) => {
        factorOptions.value = res;
      });
    });

    const parkingFormRules = {
      parking: [{ required: true, message: "请输入车场名称", trigger: "blur" }],
      project: [{ required: true, message: "请选择关联项目", trigger: "blur" }],
      chargeType: [
        {
          required: true,
          message: "请选择临停收费模板",
          trigger: "blur",
        },
      ],
      callCenter: [
        { required: true, message: "请输入呼叫中心", trigger: "blur" },
      ],
      factor: [{ required: true, message: "请选择可用厂商", trigger: "blur" }],
    };
    const chargeTypeOptions = ref([]);
    const parkingFormData = ref({
      parking: "",
      project: "",
      chargeType: [],
      companyName: "",
      callCenter: "",
      factor: "",
    });
    watch(
      () => parkingFormData.value.project,
      (v) => {
        if (v) {
          API.getChargeTemplateOptions({ project_id: v }).then((res) => {
            chargeTypeOptions.value = res;
          });
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );

    function openDialog() {
      parkingFormData.value = {
        parking: "",
        project: "",
        chargeType: [],
        companyName: "",
        callCenter: "",
        factor: "",
      };
      addParkingDialog.value.show();
    }
    function closeDialog() {
      addParkingDialog.value.close();
    }
    function handleEditParking() {
      if (parkingFormRef.value) {
        parkingFormRef.value.validate((valid) => {
          if (valid) {
            const parmas = {
              name: parkingFormData.value.parking,
              project_id: parkingFormData.value.project,
              template_id: parkingFormData.value.chargeType,
              call_tel: parkingFormData.value.callCenter,
              hardware_id: parkingFormData.value.factor,
            };
            emit("submit", parmas);
          }
        });
      }
    }
    return {
      closeDialog,
      openDialog,
      parkingFormData,
      addParkingDialog,
      parkingFormRules,
      parkingFormRef,
      factorOptions,
      projectOptions,
      chargeTypeOptions,
      handleEditParking,
    };
  },
};
</script>

<style lang="scss">
.add-Parking-dialog {
  .edit-Parking-form {
    padding-bottom: 20px;
  }
}
</style>
