<template>
  <div class="parking-container">
    <ContentTitle>{{ tableTitle }}</ContentTitle>
    <Wfilter
      :filterConfig="filterConfig"
      @openProjectDialog="openAddParkingDialog"
      @handleSearch="handleSearch"
      @changeProject="changeProject"
      @print="handlePrint"
      @export="handleExport"
    ></Wfilter>
    <Wtable
      :columns="columns"
      :pagination="pagination"
      :tableData="tableData"
      @currentChange="currentChange"
      @sizeChange="sizeChange"
      @info="goDetail"
      @activate="openActivateDialog"
    >
      <!-- <template #template="{ row }">
        <template v-if="row.template.length">
          <span
            v-for="(el, index) in row.template"
            :key="index"
            style="color: var(--text-third-color)"
            >{{ el.name }}
            <span v-if="index != row.template.length - 1">、</span>
          </span>
        </template>
      </template> -->
    </Wtable>
    <Wdialog
      ref="activateDialog"
      title="激活车场"
      width="30%"
      @wConfirm="handleActivateParking"
    >
      <el-form
        ref="activateFormRef"
        :model="activateFormData"
        :rules="activateFormRules"
        label-position="top"
        class="project-form"
      >
        <el-form-item prop="auth_code" label="授权码">
          <el-input
            v-model="activateFormData.auth_code"
            autocomplete="off"
            placeholder="请输入授权码"
            clearable
          />
        </el-form-item>
      </el-form>
    </Wdialog>
    <AddParkingDialog
      ref="addParkingDialog"
      @submit="handleAddParking"
    ></AddParkingDialog>
  </div>
</template>
<script>
import { ref, onMounted, reactive, onUnmounted, computed } from "vue";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import {
  print,
  exportExcel,
  getBtnAuth,
  getTopBtnAuth,
} from "@/utils/common.js";
import Wfilter from "@/components/wFilter/wFilter.vue";
import Wtable from "@/components/wTable/wTable.vue";
import Wdialog from "@/components/wDialog/wDialog.vue";
import ContentTitle from "@/components/contentTitle/contentTitle.vue";
import AddParkingDialog from "./components/AddParkingDialog.vue";

import API from "@/plugins/api.js";
import config from "./index.json";

export default {
  components: {
    Wtable,
    Wfilter,
    Wdialog,
    ContentTitle,
    AddParkingDialog,
  },
  setup() {
    const router = useRouter();
    const filterConfig = reactive(config.filterConfig);
    const columns = reactive(config.tableConfig.list);
    const defaultBtns = reactive(config.tableConfig.btns);
    const pagination = config.tableConfig.pagination;
    const tableData = ref([]);
    const keywords = ref("");
    const projectId = ref("");
    const page = ref(1);
    const customerPayOption = ref([]);
    const tableTitle = ref("车场管理");
    const store = useStore();
    const rightBtn = computed(() => store.state.saasConfig.rightBtn); //右侧页面的所有权限信息
    // 获取权限按钮
    columns[columns.length - 1].btns = getBtnAuth(rightBtn.value, defaultBtns); // 获取有权限查看或操作的表格列
    filterConfig.btns = getTopBtnAuth(rightBtn.value, filterConfig.btns);
    async function fetchData(parmas) {
      API.getParking({
        ...parmas,
        limit: pagination.pageSize,
        keywords: keywords.value,
        project_id: projectId.value,
        page: page.value,
      }).then((res) => {
        if (res.data.length) {
          res.data.forEach((el) => {
            const customerPayObj = customerPayOption.value.find(
              (item) => item.id === el.id
            );
            el.customer_pay_id = customerPayObj ? customerPayObj.wx_mch_id : "";
            el.showActivate = el.is_activate ? 0 : 1; // 已经激活了就不显示激活按钮
            el.templateName = "";
            if (el.template.length) {
              el.template.forEach((el2, index2) => {
                el.templateName += el2.name;
                if (index2 != el.template.length - 1) {
                  el.templateName += "、";
                }
              });
            }
          });
        }
        pagination.total = res.total;
        tableData.value = res.data;
      });
    }
    function getCustomerPay() {
      API.getCustomerPay().then((res) => {
        customerPayOption.value = res;
      });
    }
    onMounted(async () => {
      try {
        await getCustomerPay();
        fetchData();
        API.getProjectOptions({}).then((res) => {
          filterConfig.selects[0].mapData = res;
        });
      } catch (error) {
        console.error(error);
      }
    });

    const currentChange = (v) => {
      page.value = v;
      fetchData();
    };
    const sizeChange = (v) => {
      pagination.pageSize = v;
      fetchData();
    };
    const changeProject = (pro) => {
      projectId.value = pro.value;
      fetchData();
    };
    const handleSearch = (kwd) => {
      keywords.value = kwd;
      fetchData();
    };

    function goDetail(data) {
      router.push({
        path: "/parking-detail",
        query: {
          id: data.row.id,
        },
      });
    }
    const activateDialog = ref(null);
    const activateFormRef = ref(null);
    const activateFormRules = {
      auth_code: [
        {
          required: true,
          message: "请输入授权码",
          trigger: "blur",
        },
      ],
    };
    const activateFormData = ref({
      auth_code: "",
      id: "",
    });
    function openActivateDialog(data) {
      if (data && data.row) {
        activateFormData.value["id"] = data.row.id;
      }
      activateDialog.value.show();
    }
    function handleActivateParking() {
      if (activateFormRef.value) {
        activateFormRef.value.validate((valid) => {
          if (valid) {
            API.activateParking(activateFormData.value).then(() => {
              ElMessage.success({
                message: "操作成功",
              });
              fetchData({});
              activateDialog.value.close();
            });
          }
        });
      }
    }
    const addParkingDialog = ref(null);
    function openAddParkingDialog() {
      addParkingDialog.value.openDialog();
    }
    function handleAddParking(data) {
      API.addParking(data).then(() => {
        ElMessage.success({
          message: "操作成功",
        });
        fetchData();
        addParkingDialog.value.closeDialog();
      });
    }
    const propertiesConfig = ref([
      { field: "name", displayName: "车场名称" },
      { field: "entrance_num", displayName: "道闸数量" },
      { field: "electricpile_num", displayName: "充电桩数量" },
      { field: "customer_pay_id", displayName: "微信商户号" },
      { field: "templateName", displayName: "临停收费模板" },
      { field: "gate_offline_num", displayName: "道闸离线统计" },
      { field: "electricpile_offline_num", displayName: "充电桩离线统计" },
      { field: "parking_charge", displayName: "临停收费合计" },
      { field: "charge_charge", displayName: "充电收费合计" },
    ]);
    function handlePrint() {
      print(tableTitle.value, tableData.value, propertiesConfig.value);
    }
    function handleExport() {
      try {
        exportExcel(tableTitle.value, tableData.value, propertiesConfig.value);
        ElMessage.success({
          message: "导出成功！",
        });
      } catch (e) {
        ElMessage.error({
          message: "导出失败！",
        });
      }
    }
    onUnmounted(() => {
      filterConfig.selects[0].value = "";
    });
    return {
      tableData,
      columns,
      pagination,
      currentChange,
      sizeChange,
      openAddParkingDialog,
      filterConfig,
      changeProject,
      handleSearch,
      goDetail,
      handlePrint,
      handleExport,
      tableTitle,
      openActivateDialog,
      activateDialog,
      handleActivateParking,
      activateFormData,
      activateFormRules,
      activateFormRef,
      handleAddParking,
      addParkingDialog,
    };
  },
};
</script>

<style lang='scss'>
.parking-container {
}
</style>